import React, { useEffect } from "react";
import useInvoices from "./useInvoices";
import { DateTime } from "luxon";
import useStore from "../../../../../context/useStore";
import Loader from "../../../../common/Loader";
import Table from "../../../../common/Table/Table";
import tools from "../../../../../helpers/tools";

const Invoices = ({ subscriptionId }) => {
  const [state] = useStore();
  const { invoices, getInvoices, isLoading } = useInvoices(subscriptionId);

  const { INVOICE_STATUS } = state.constants.items;

  useEffect(() => {
    getInvoices();
  }, [subscriptionId]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <strong className="text-dark">Factures</strong>
      <Table
        fields={[
          {
            name: "",
            path: "*",
            render: (el) => {
              return (
                <div>
                  {el.createdAt && (
                    <div>
                      {DateTime.fromISO(el.createdAt).toFormat("dd/MM/yyyy")}
                    </div>
                  )}
                  <small>{el.reference}</small>
                </div>
              );
            },
          },
          {
            name: "",
            path: "amount",
            render: (el) => {
              return `${tools.truenumberWithSpaces(el / 100)}€ TTC`;
            },
          },
          {
            name: "",
            path: "*",
            render: (el) => {
              if (!el.id) return null;
              var ref = tools.findIn(INVOICE_STATUS, el.status, "id");
              return (
                <div
                  className="d-flex flex-wrap align-items-center"
                  style={{ gap: 8 }}
                >
                  <div
                    className={`p-2 text-${ref.color}`}
                    style={{ fontSize: 14 }}
                  >
                    <i className={`fa fa-${ref.icon} me-1`} />
                    {ref.name}
                  </div>
                  {el.generatedAt ? (
                    <a target="_blank" href={`/invoice/${el.id}/download`}>
                      Ouvrir
                    </a>
                  ) : (
                    "Adresse manquante"
                  )}
                </div>
              );
            },
          },
        ]}
        datas={
          invoices?.length > 0
            ? invoices
            : [
                {
                  reference: "Aucune facture",
                },
              ]
        }
      />
    </>
  );
};

export default Invoices;
