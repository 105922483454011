import React from "react";

const AddressItem = ({ address }) => {
  return (
    <li className="list-group-item d-flex align-items-center flex-row flex-wrap justify-content-between bg-default">
      {address ? (
        <span>
          <strong className="text-uppercase">{address.name} - </strong>{" "}
          {address.address1} {address.address2} {address.postalCode}{" "}
          {address.city}
        </span>
      ) : (
        <span>Aucune adresse n'a été ajoutée.</span>
      )}
    </li>
  );
};

export default AddressItem;
